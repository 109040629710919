
<template>
  <v-container
    id="my-groups"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <base-v-component
      heading="Groups"
      sub-heading="Groups of Contacts"
    />

    <template>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-spacer />

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 ml-4"
              v-bind="attrs"
              v-on="on"
            >
              Add Group
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      required
                      label="Name *"
                      :error="!editedItem.name && isFormDirty"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-select
                      v-model="selectedSenderID"
                      :items="senders"
                      label="Sender *"
                      required
                      :error="!selectedSenderID && isFormDirty"
                      @change="updateSender"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.inviteCode"
                      label="Invite Code"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.welcomeMessage"
                      label="Welcome Message"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.sendGridID"
                      label="SendGrid ID"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>* indicates required field</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <v-data-table
      :headers="headers"
      :items="groups"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <div class="senderActions">
          <v-btn
            color="primary"
            outlined
            x-small
            class="ml-4"
            @click="editItem(item)"
          >
            Edit
          </v-btn>
          <router-link :to="{ name: 'Group Contacts', params: { groupId: item.id }}">
            <v-btn
              color="primary"
              outlined
              x-small
              class="ml-4"
            >
              View Contacts
            </v-btn>
          </router-link>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No groups to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>

    <share-group-dialog
      :selected-group="selectedGroupToShare"
      :is-visible="isGroupShareDialogVisible"
      :users="allUsers"
      @on-submit="onGroupShareSubmit"
      @on-close="hideGroupShareDialog"
      @on-success="onGroupShareSuccess"
      @on-error="onGroupShareError"
    />

    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    components: {
      ShareGroupDialog: () => import('../components/ShareGroupDialog'),
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Members', value: 'membercnt' },
        { text: 'Messages', value: 'messagecnt' },
        { text: 'Sender', value: 'senderName' },
        { text: 'Invite Code', value: 'inviteCode' },
        { text: 'Welcome Message', value: 'welcomeMessage' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      snackbar: false,
      notification: 'Unknown',
      groups: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        name: '',
        members: '-',
        messages: '-',
        inviteCode: '',
        welcomeMessage: '',
        sendGridID: '',
      },
      defaultItem: {
        id: -1,
        name: '',
        members: '-',
        messages: '-',
        inviteCode: '',
        welcomeMessage: '',
        sendGridID: '',
      },
      senders: [],
      isOverlayVisible: false,
      selectedSenderID: '',
      isFormDirty: false,
      selectedGroupToShare: null,
      isGroupShareDialogVisible: false,
      allUsers: [],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Group' : 'Edit Group'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
        this.groups = []

        try {
          this.isOverlayVisible = true
          // Combine multiple calls to minimise the network call time
          const apiCalls = await Promise.all([
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}groups?code=${this.apiCode}`),
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}senders?code=${this.apiCode}`),
          ])
          const groupsResponse = apiCalls[0]
          const sendersResponse = apiCalls[1]
          groupsResponse.data.forEach((group) => {
            this.groups.push({
              id: group.groupID,
              name: group.name,
              messagecnt: group.messageCnt,
              membercnt: group.memberCnt,
              inviteCode: group.inviteCode,
              senderName: group.senderName,
              welcomeMessage: group.welcomeMessage,
              sendGridID: group.welcomeSGID,
            })
          })
          sendersResponse.data.forEach((sender) => {
            this.senders.push({
              text: sender.senderName,
              value: sender.senderID,
            })
          })
          await this.fetchUsers()
          this.close()
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },

      editItem (item) {
        this.editedIndex = this.groups.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        if (confirm('Are you sure you want to delete this group?')) {
          this.httpConnector.makeRequest('delete', `${this.apiEndpoint}groups/${item.id}?code=${this.apiCode}`)
            .then((res) => {
              this.notification = 'Group has been deleted'
              this.snackbar = true

              const index = this.groups.indexOf(item)
              this.groups.splice(index, 1)
            })
            .catch((error) => {
              this.notification = 'Unable to delete the group'
              this.snackbar = true

              throw error
            })
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = {
            id: -1,
            name: '',
            members: '-',
            messages: '-',
            inviteCode: '',
            welcomeMessage: '',
          }
          this.editedIndex = -1
          this.selectedSenderID = 0
          this.isFormDirty = false
        })
      },

      isFormValid () {
        let isValid = true
        if (!this.editedItem.name || !this.editedItem.name.length) {
          isValid = false
        }
        if (!this.selectedSenderID) {
          isValid = false
        }
        return isValid
      },

      save () {
        if (this.editedIndex > -1) {
          // Saving exisiting group
          const form = {
            Name: this.editedItem.name,
            InviteCode: this.editedItem.inviteCode,
            WelcomeMessage: this.editedItem.welcomeMessage,
            WelcomeSGID: this.editedItem.sendGridID,
          }
          this.isOverlayVisible = true
          this.httpConnector.makeRequest('put', `${this.apiEndpoint}groups/${this.editedItem.id}/?code=${this.apiCode}`, form)
            .then(async (res) => {
              this.notification = 'Group has been updated'
              this.snackbar = true

              await this.initialize()
              this.close()
              this.isOverlayVisible = false
            })
            .catch((error) => {
              this.notification = 'Unable to update the group'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        } else {
          this.isFormDirty = true
          if (!this.isFormValid()) {
            this.notification = 'Fill all the required fields'
            this.snackbar = true
            return
          }
          this.isOverlayVisible = true
          // New group
          const form = {
            Name: this.editedItem.name,
            SenderID: this.selectedSenderID,
            CreatedBy: this.usremail,
            InviteCode: this.editedItem.inviteCode,
            WelcomeMessage: this.editedItem.welcomeMessage,
          }

          this.httpConnector.makeRequest('post', `${this.apiEndpoint}groups?code=${this.apiCode}`, form)
            .then(async (res) => {
              this.notification = 'Group has been created'
              this.snackbar = true

              await this.initialize()
              this.close()
              this.isOverlayVisible = false
            })
            .catch((error) => {
              this.notification = 'Unable to create the group'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        }
      },

      updateSender (senderID) {
        this.selectedSenderID = senderID
      },

      showGroupShareDialog (groupId) {
        this.selectedGroupToShare = groupId
        this.isGroupShareDialogVisible = true
      },

      onGroupShareSubmit () {
        this.isOverlayVisible = true
      },

      hideGroupShareDialog () {
        this.selectedGroupToShare = null
        this.isGroupShareDialogVisible = false
      },

      onGroupShareSuccess () {
        this.isOverlayVisible = false
        this.hideGroupShareDialog()
      },

      onGroupShareError () {
        this.isOverlayVisible = false
      },

      async fetchUsers () {
        try {
          const usersResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}users?code=${this.apiCode}`)
          this.allUsers = usersResponse.data
        } catch (error) {}
      },
    },
  }

</script>

<style scoped>
  .v-overlay.v-overlay--active.theme--dark {
    z-index: 300!important;
  }
  .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
</style>
